import React from 'react'
import './CONNECTWALLETCur.css'

function CONNECTWALLETCur(props) {
  const { spanText1, spanText2, clickHandler } = props

  return (
    <a href="#" onClick={clickHandler}>
      <p className="connect-wallet-cur inter-bold-quarter-spanish-white-32px">
        <span className="span0 inter-bold-quarter-spanish-white-32px">
          {spanText1}
        </span>
        <span className="span1-2">{spanText2}</span>
      </p>
    </a>
  )
}

export default CONNECTWALLETCur
