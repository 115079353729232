import React from 'react'
import CONNECTWALLETCur from '../CONNECTWALLETCur'
import './DisplayLandingPage.css'

function DisplayLandingPage(props) {
  const {
    frame,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    x2022ManyfoldPte,
    cONNECTWALLETCurProps,
    clickHandler,
  } = props

  return (
    <div className="container-center-horizontal">
      <div className="display-landing-page screen">
        <div className="header">
          <div className="logo">
            <img className="frame" src={frame} alt="Frame" />
          </div>
          <div className="playstore">
            <a
              href="https://play.google.com/store/apps/details?id=com.kami.kamitv"
              target={'_blank'}
            >
              <img src="/img/Google_Play-Badge-Logo.wine.svg" />
            </a>
          </div>
        </div>
        <p className="welcome-to-the-kami inter-normal-quarter-spanish-white-25px">
          <span className="inter-normal-quarter-spanish-white-25px sp1">
            {spanText1}
          </span>
          <span className="span1">{spanText2}</span>
        </p>
        <div className="overlap-group">
          <h1 className="rent-display-use">
            <span className="inter-extra-light-tundora-55px">{spanText3}</span>
            <span className="span1-1">{spanText4}</span>
            <span className="inter-extra-light-tundora-55px">{spanText5}</span>
          </h1>
          <button className="frame-190">
            <CONNECTWALLETCur
              spanText1={cONNECTWALLETCurProps.spanText1}
              spanText2={cONNECTWALLETCurProps.spanText2}
              clickHandler={clickHandler}
            />
          </button>
          <p className="x2022-manyfold-pte">{x2022ManyfoldPte}</p>
        </div>
      </div>
    </div>
  )
}

export default DisplayLandingPage
