import '../App.css'

import React, { useEffect, useRef, useState } from 'react'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js'
import { ethers } from 'ethers'
import DisplayLandingPage from '../components/DisplayLandingPage'

import logo from '../assets/kami-logo.svg'
import App from '../App'

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: 'b8ced5bdaa634dcbb336c2b885d3754e',
    },
  },
}

function Connect(props) {
  const { walletIsConnected } = props
  const [walletConnected, setWalletConnected] = useState(false)
  const web3ModalRef = useRef()
  const [initialising, setInitialising] = useState(true)
  const [cState, setcState] = useState({
    provider: null,
    signer: null,
    address: null,
    chain: 0x1,
    balance: 0,
  })

  const connectWallet = async () => {
    // if (walletConnected) return
    // try {
    // await getSignerOrProvider()
    const instance = await web3ModalRef.current.connect()
    const provider = new ethers.providers.Web3Provider(instance)
    App.currentProvider = provider
    await provider.send('eth_requestAccounts', []).catch((err) => {
      console.log(err)
      return
    })
    const signer = provider.getSigner()
    const address = await signer.getAddress()
    const chain = await signer.getChainId()
    console.log('Wallet Connection:', { address, chain })

    setcState({
      provider,
      signer,
      address,
      chain,
    })
    setWalletConnected(true)
    walletIsConnected({ address, chain })
    // } catch (err) {
    //   console.log(err.message)
    // }
  }

  useEffect(() => {
    setInitialising(true)

    web3ModalRef.current = new Web3Modal({
      theme: 'dark',
      cacheProvider: true,
      disableInjectedProvider: false,
      providerOptions,
    })
    App.web3ModalRef = web3ModalRef

    if (localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')) {
      connectWallet().then((_) => {
        return null
      })
    } else setInitialising(false)
  }, [])

  return (
    <>
      {!initialising && (
        <DisplayLandingPage
          clickHandler={connectWallet}
          {...displayLandingPageData}
        />
      )}
    </>
  )
}

export default Connect

const cONNECTWALLETCurData = {
  spanText1: (
    <React.Fragment>
      CONNECT WALLET
      <br />
    </React.Fragment>
  ),
  spanText2: '* Current support for ERC4907 and ERC721',
}

const displayLandingPageData = {
  frame: '/img/frame@2x.png',
  spanText1: (
    <React.Fragment>
      Welcome to the KAMI Web3 Player - the first of its kind app that turns
      your beloved NFTs into your personal gallery on your favourite displays.
      <br />
      <br />
    </React.Fragment>
  ),
  spanText2: <React.Fragment></React.Fragment>,
  spanText3: (
    <React.Fragment>
      Rent.
      <br />
    </React.Fragment>
  ),
  spanText4: (
    <React.Fragment>
      Display.
      <br />
    </React.Fragment>
  ),
  spanText5: (
    <React.Fragment>
      Use.
      <br />
      Mint.Buy.
      <br />
      Sell.
      <br />
      Interact.
      <br />
      Earn.
    </React.Fragment>
  ),
  x2022ManyfoldPte: '© 2023 Manyfold Pte Ltd. All Rights Reserved.',
  cONNECTWALLETCurProps: cONNECTWALLETCurData,
}
