import './App.css'
import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Connect from './pages/Connect'
import ListNFTs from './pages/ListNFTs'

var currentProvider, web3ModalRef

const Home = ({ wallet, walletConnected }) => {
  return (
    <div>
      {wallet == null && <Connect walletIsConnected={walletConnected} />}
      {wallet != null && (
        <ListNFTs walletAddress={wallet.address} chain={wallet.chain} />
      )}
    </div>
  )
}

function App() {
  const [wallet, setWallet] = useState(null)

  useEffect(() => {
    document.title = 'KAMI Web3 Player'
  }, [])

  function walletConnected({ address, chain }) {
    console.log('Callback...')
    setWallet({ address, chain })
  }

  document.body.style.backgroundColor = 'rgba(25, 25, 25, 30)'

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={<Home wallet={wallet} walletConnected={walletConnected} />}
        ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
