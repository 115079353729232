/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import '../App.css'
import './ListNFTs.css'
import React, { useEffect, useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import logo from '../assets/kami-logo.svg'
// import SelectDeviceModal from './modals/SelectDeviceModal'
// import TVRemoteInstructions from '../components/TVRemoteInstructions'
import App from '../App'

let nftInfo

async function sendChangeWallet(wallet, chain) {
  console.log('sendChangeWallet')

  const cwStr = localStorage.getItem('currentWallet')
  if (cwStr != null) {
    const cw = JSON.parse(cwStr)
    // if (cw.address !== wallet && cw.chainId !== chain) {
    const oldWallet = cw
    const newWallet = { address: wallet, chainId: chain }
    axios
      .post(
        `https://tv.kamiunlimited.com/tv/changeWallet`,
        { oldWallet, newWallet },
        {
          headers: {
            auth: 'bb5e708d-6239-4e6e-a034-885b9e649c21',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        if (res.data.success) {
          console.log('Wallet updated!')
          localStorage.setItem('currentWallet', JSON.stringify(newWallet))
        } else {
          console.log('Failed updating wallet')
        }
      })
      .catch((err) => {
        console.error(`Error: ${err.message}`)
      })
    // }
  } else {
    const newWallet = { address: wallet, chainId: chain }
    localStorage.setItem('currentWallet', JSON.stringify(newWallet))
  }
}

export function ListNFTs(props) {
  let { walletAddress, chain } = props

  // walletAddress = '0x4EE2f9E69c872fe0A75750F9Fc0D17ecD9F189B4'
  // chain = '0x1'

  console.log('Wallet Info: ', props)

  sendChangeWallet(walletAddress, chain)

  const moralisIpfsPrefix = 'https://ipfs.moralis.io:2053/ipfs/'
  const [walletConnected, setWalletConnected] = useState(true)
  const [showDevices, setShowDevices] = useState(false)
  const handleCloseDevices = () => setShowDevices(false)
  const handleShowDevices = () => setShowDevices(true)

  const getTvInfo = () => {
    console.log('metadata', nftInfo)
    const { chain, tokenAddress, tokenId } = nftInfo
    return {
      nftInfo: {
        chain,
        tokenAddress,
        tokenId,
      },
    }
  }

  const handleDeviceSelected = (device) => {
    handleCloseDevices()

    if (nftInfo == null) {
      toast.warning('No NFT')
      return
    }
    const tvInfo = getTvInfo()
    console.log('tvIfo', tvInfo)
    axios
      .post(
        `https://tv.kamiunlimited.com/tv/displays/nft/${device.device_id}`,
        tvInfo,
        {
          headers: {
            auth: 'bb5e708d-6239-4e6e-a034-885b9e649c21',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        if (res.data.success)
          toast.success(`${nftInfo.metadata.name} Sent to '${device.name}'`)
        else
          toast.error(`An error occurred sending the NFT to '${device.name}'`)
      })
      .catch((err) => {
        toast.error(`Error: ${err.message}`)
      })
  }

  let fetching = false

  const fetchNftCollection = async () => {
    if (fetching) return
    fetching = true
    console.log('Send request')
    const url = `https://tv.kamiunlimited.com/tv/collection/${walletAddress}/${chain}`
    const response = await axios.get(url, {
      headers: {
        auth: 'bb5e708d-6239-4e6e-a034-885b9e649c21',
        'Content-Type': 'application/json',
      },
    })
    console.log('Response: ', response.data)
    setNFTs(response.data)
    fetching = false
  }

  const sendToTV = (nft) => {
    console.log('Sending', nft.metadata.name)
    nftInfo = nft
    handleShowDevices()
  }

  const [displayHelp, setDisplayHelp] = useState(false)

  const handleHelpClicked = () => {
    setDisplayHelp(true)
  }

  const handleDisconnectClicked = () => {
    console.log('PROVIDR: ', App.currentProvider)
    console.log('W3Modal: ', App.web3ModalRef)

    App.web3ModalRef.current.clearCachedProvider()
    window.localStorage.clear()
    const btn = document.getElementById('disconnect-button')
    btn.innerHTML = 'Disconnected'
    setWalletConnected(false)
    window.location.reload()
  }

  const [NFTs, setNFTs] = useState(null)

  useEffect(() => {
    fetchNftCollection()
  }, [])

  const toggleHelp = () => {
    setDisplayHelp(!displayHelp)
  }

  if (NFTs !== null) {
    const nftItems = NFTs.map((nft) => {
      console.log('NFT:', nft)
      if (nft.metadata && nft.metadata.image) {
        let imageUrl = nft.metadata.image
        if (imageUrl.startsWith('ipfs://'))
          imageUrl = imageUrl.replace('ipfs://', moralisIpfsPrefix)
        return (
          <div className="nft" key={nft.blockNumber}>
            <img
              src={imageUrl || ''}
              alt={nft.metadata?.name || 'invalid metadata'}
              style={{ display: 'none' }}
              onLoad={(event) => (event.target.style.display = 'flex')}
            />
          </div>
        )
      } else {
        return null
      }
    })

    /* 
    <a href="#" className="send-btn" onClick={() => sendToTV(nft)}>
      Send to TV
    </a>
    <SelectDeviceModal
      walletAddress={walletAddress}
      isOpen={showDevices}
      close={handleCloseDevices}
      selected={handleDeviceSelected}
    /> 
  */

    if (!displayHelp) {
      return (
        <>
          {/* <ToastContainer
            position="top-center"
            theme="colored"
            autoClose={5000}
            // hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            pauseOnHover={true}
            closeButton={false}
            // icon={renderIcon}
            style={{ width: '600px' }}
          /> */}
          <nav className="navbar">
            <div className="brand-logo">
              <img className="kami-logo" src={logo} alt="KAMI Logo" />
            </div>
            <div className="navbar-links">
              <ul>
                <li>
                  <a
                    id="disconnect-button"
                    href="#"
                    onClick={handleDisconnectClicked}
                  >
                    Disconnect Wallet
                  </a>
                </li>
                <li>
                  <a href="#" onClick={handleHelpClicked}>
                    HELP
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <header>
            <h1>Your NFT Collection</h1>
            <div>
              <h4>
                Post a picture or video of you using the Web3 Player and tag us
                at #KAMIIT
                <br />
                <div className="support">
                  For support please contact us via{' '}
                  <a className="link" href="mailto:support@kamiunlimited.com">
                    email
                  </a>{' '}
                  or
                  <a
                    className="link"
                    href="https://t.me/KAMI_NFT_Player_Support"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Telegram
                  </a>
                </div>
              </h4>
            </div>
          </header>
          <main>
            <div className="cards-container">{nftItems}</div>
            <div className="spacer"></div>
          </main>
          <footer>© 2023 Manyfold Pte Ltd. All Rights Reserved.</footer>
        </>
      )
    } else {
      window.location.href = 'https://tv.kamiunlimited.com/help'
      // return <TVRemoteInstructions closeButton={toggleHelp} />
    }
  } else {
    return <h2>No data</h2>
  }
}

export default ListNFTs
